"use client";

import { GoogleAnalytics } from "@next/third-parties/google";
import Script from "next/script";
import "./globals.css";
import { Roboto } from "next/font/google";
import { ThemeProvider } from "@/context/ThemeProvider";
import { Toaster } from "sonner";
import BackToTop from "@/components/back-to-top";
import { usePathname } from "next/navigation";
import { BubbleChat } from "flowise-embed-react";
import { siteConfig } from "@/config/site";

// Define a type for page metadata
type PageMetadata = {
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
};

// Define metadata for each page
const metadata: { [key: string]: PageMetadata } = {
  "/": {
    title: "Mubarokah: Digital Creative Agency",
    description:
      "Mubarokah Digital Creative Agency specializes in web development, digital marketing, mobile app development, and more. Our innovative solutions help businesses enhance their online presence and achieve their goals. Discover how we can transform your digital strategy and drive success.",
    ogTitle: "Mubarokah: Digital Creative Agency",
    ogDescription:
      "Mubarokah Digital Creative Agency specializes in web development, digital marketing, mobile app development, and more. Our innovative solutions help businesses enhance their online presence and achieve their goals. Discover how we can transform your digital strategy and drive success.",
    ogImage:
      "https://mubarokah-landing-page.vercel.app/GraphImageWebMubarokah.webp",
  },
  "/projects": {
    title: "Projects - Mubarokah Digital Creative Agency",
    description: "Discover our amazing projects.",
    ogTitle: "Projects - Mubarokah Digital Creative Agency",
    ogDescription: "Discover our amazing projects.",
    ogImage: "/images/projects-og-image.jpg",
  },
  "/whatsapp-link-generator": {
    title:
      "Create WhatsApp Links Effortlessly - Mubarokah Digital Creative Agency",
    description:
      "Discover an easy way to create WhatsApp links for business interactions or personal communication. Our tool helps you connect with customers directly and efficiently. Try it now!",
    ogTitle:
      "Create WhatsApp Links Effortlessly - Mubarokah Digital Creative Agency",
    ogDescription:
      "Discover an easy way to create WhatsApp links for business interactions or personal communication. Our tool helps you connect with customers directly and efficiently. Try it now!",
    ogImage: "/images/projects-og-image.jpg",
  },
  // Add more pages as needed
  default: {
    title: "Mubarokah Digital Creative Agency",
    description: "Your go-to digital creative agency.",
    ogTitle: "Mubarokah Digital Creative Agency",
    ogDescription: "Your go-to digital creative agency.",
    ogImage: "/images/default-og-image.jpg",
  },
};

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "500", "700"],
  display: "swap",
});

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  return (
    <html lang="en" suppressHydrationWarning>
      <head>
        {/* Meta Pixel Code */}
        <Script id="meta-pixel" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '902647118390312');
            fbq('track', 'PageView');
          `}
        </Script>
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=902647118390312&ev=PageView&noscript=1"
            alt="Meta Pixel"
          />
        </noscript>
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta name="keywords" content={siteConfig.keywords.join(", ")} />
      </head>
      <body className={`${roboto.className} dark:bg-slate-850 dark:text-slate-400`}>
        <GoogleAnalytics gaId="G-EBHGYMR32B" />
        {/* Google Tag Manager */}
        <Script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=AW-16656255205`}
        />
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16656255205');
          `}
        </Script>

        {/* LinkedIn Insight Tag */}
        <Script id="linkedin-partner-script" strategy="afterInteractive">
          {`
    _linkedin_partner_id = "6541612";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `}
        </Script>
        <Script id="linkedin-analytics-script" strategy="afterInteractive">
          {`
    (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
  `}
        </Script>
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=6541612&fmt=gif"
          />
        </noscript>
        {/* Microsoft Clarity Analytics */}
        <Script id="clarity-script" strategy="afterInteractive">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "mvziy5sppy");
          `}
        </Script>
        {/* Cloudflare Web Analytics */}
        <Script
          defer
          src='https://static.cloudflareinsights.com/beacon.min.js'
          data-cf-beacon='{"token": "52edb60ea7ff45c198b88e0b8e518734"}'
        />
        {/* End Cloudflare Web Analytics */}
        {/* Muna Asisten Popup Chat */}
        {!pathname?.includes("/muna-assistant") && (
          <BubbleChat
            chatflowid="ef1d2eaa-84a1-4b33-8766-0570f30c21c3"
            apiHost="https://flows.mubarokah.com"
            theme={{
              tooltip: {
                showTooltip: false,
                tooltipMessage:
                  "Hi There 👋! I am always online and ready to help you.",
                tooltipBackgroundColor: "black",
                tooltipTextColor: "white",
                tooltipFontSize: 16,
              },
              button: {
                backgroundColor: "#0C0F3C",
              },
              chatWindow: {
                showTitle: true,
                title: "Muna Assistant",
                titleAvatarSrc:
                  "/muna/muna-avatar.webp",
                showAgentMessages: true,
                welcomeMessage: "Hi there! How can I help?",
                errorMessage:
                  "Oops.. something went wrong. Contact the administrator at chatbotdev@mubarokah.com",
                backgroundColor: "#ffffff",
                height: 700,
                width: 400,
                fontSize: 16,
                poweredByTextColor: "#303235",
                botMessage: {
                  backgroundColor: "#f7f8ff",
                  textColor: "#303235",
                  showAvatar: true,
                  avatarSrc:
                    "/muna/muna-avatar.webp",
                },
                userMessage: {
                  backgroundColor: "#3B81F6",
                  textColor: "#ffffff",
                  showAvatar: true,
                  avatarSrc:
                    "/muna/me.webp",
                },
                textInput: {
                  placeholder: "Type your question",
                  backgroundColor: "#ffffff",
                  textColor: "#303235",
                  sendButtonColor: "#3B81F6",
                  maxChars: 150,
                  maxCharsWarningMessage:
                    "You exceeded the characters limit. Please input less than 150 characters.",
                  autoFocus: true,
                  sendMessageSound: true,
                  receiveMessageSound: true,
                },
                feedback: {
                  color: "#303235",
                },
                footer: {
                  textColor: "#303235",
                  text: "Powered by",
                  company: "Mubarokah AI (based on Flowise)",
                  companyLink: "https://mubarokah.com/",
                },
              },
            }}
          />
        )}
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          {children}
        </ThemeProvider>
        <Toaster richColors />
        <BackToTop />
      </body>
    </html>
  );
}
